<template>
  <div>
    <a-alert
      type="info"
      :showIcon="true"
    >
      <div slot="message">
        当前选择：
        <span
          v-if="currSelected.title"
          style="color:black"
        >{{ getCurrSelectedTitle() }}</span>
        <a
          v-if="currSelected.title"
          @click="onClearSelected"
          style="margin-left: 10px;color:#3853db"
        >取消选择</a>
      </div>
    </a-alert>
    <a-input-search
      @search="onSearch"
      style="width:100%;margin-top: 10px"
      placeholder="请输入部门名称"
    />
    <!-- 树-->
    <a-dropdown
      :trigger="[this.dropTrigger]"
      @visibleChange="dropStatus"
    >
      <a-tree
        style="user-select: none;height: 70vh;overflow-y: auto;"
        multiple
        @select="onSelect"
        @check="onCheck"
        :selectedKeys="selectedKeys"
        :checkedKeys="checkedKeys"
        :treeData="departTree"
        :load-data="onLoadData"
        :checkStrictly="true"
        :expandedKeys="iExpandedKeys"
        :autoExpandParent="autoExpandParent"
        @expand="onExpand"
      />
    </a-dropdown>
  </div>
</template>

<script>
import api from '../../../system/user/user'
export default {
  data () {
    return {
      // 树形部分
      departTree: [],
      departTreeCopy: [],
      treeData: [],
      currSelected: {},
      crChargeDept: '', // 分页查询部门ID
      checkedKeys: [],
      selectedKeys: [],
      autoExpandParent: true,
      iExpandedKeys: [],
      dropTrigger: ''
    }
  },
  mounted () {
    this.loadTree()
  },
  methods: {
    // 树形部分代码  当前选择显示
    getCurrSelectedTitle () {
      return !this.currSelected.title ? '' : this.currSelected.title
    },
    onClearSelected () {
      this.checkedKeys = []
      this.currSelected = {}
      this.selectedKeys = []
      this.$emit('onSelect', { deptId: '' })
    },
    onSearch (value) {
      const that = this
      if (value) {
        api.searchByKeywords({ keyWord: value }).then((res) => {
          that.departTree = []
          for (let i = 0; i < res.data.length; i++) {
            const temp = res.data[i]
            that.departTree.push(temp)
          }
        })
      } else {
        this.iExpandedKeys = []
        this.loading = true
        this.departTree = this.departTreeCopy
        this.loading = false
      }
    },
    loadTree () {
      this.$nextTick(() => {
        api.queryDepartTreeList().then((res) => {
          if (res.data.length > 0) {
            this.departTree = res.data
          } else {
            this.$message.warning('您没有该权限！')
          }
          this.loading = false
          this.departTreeCopy = this.departTree
        })
      })
    },
    onLoadData (treeNode) {
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          api.queryDepartTreeList({ parentId: treeNode.dataRef.id }).then((res) => {
            if (res.data.length > 0) {
              treeNode.dataRef.children = res.data
            } else {
              // this.$message.warning('该部门下没有子部门！')
              treeNode.dataRef.leaf = true
            }
            this.loading = false
          })
          resolve()
          return
        }
        setTimeout(() => {
          this.departTree = [...this.departTree]
          resolve()
        }, 1000)
      })
    },
    onSelect (selectedKeys, e) {
      const record = e.node.dataRef
      this.currSelected = Object.assign({}, record)
      this.selectedKeys = [record.key]
      this.setValuesToForm(record)
    },
    // 触发onSelect事件时,为部门树右侧的form表单赋值
    setValuesToForm (record) {
      this.$emit('onSelect', { deptId: record.id, deptName: record.deptName })
    },
    onCheck (checkedKeys, info) {
      this.checkedKeys = checkedKeys.checked
    },
    onExpand (expandedKeys) {
      this.iExpandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    // 右键点击下拉框改变事件
    dropStatus (visible) {
      if (visible === false) {
        this.dropTrigger = ''
      }
    }
  }
}
</script>

<style>
</style>
